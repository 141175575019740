import React from "react";
import logo1 from "./../../assets/imgs/VENADO_FT_LALIGA.png";
import logo2 from "./../../assets/imgs/legales2.png";
const Nav = () => {
  return (
    <nav className="navbar fixed-top  d-flex justify-content-between">
      <img src={logo1} alt="Left" className="navbar-brand w-25 ps-5" />
      <img src={logo2} alt="Right" className="pe-5" />
    </nav>
  );
};

export default Nav;
