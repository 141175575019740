import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Lottery from "./Screens/Lottery/Lottery";
import Error404 from "./Components/Error404/Error404";
import ComingSoon from "./Components/ComingSoon/ComingSoon"; // Asegúrate de crear este componente
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "material-icons/iconfont/material-icons.css";
import { useState } from "react";

function App() {
  // Estado para controlar si el componente Lottery está activo
  const [isLotteryActive, setIsLotteryActive] = useState(true); // Puedes ajustar el valor inicial según tu lógica

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={isLotteryActive ? <Lottery /> : <ComingSoon />}
          />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
