import React from "react";
import Nav from "../nav/Nav";

function ComingSoon() {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column min-vh-100 bg-image-main-1">
      <Nav />
      <div className="container-text2">
        <h1 className=" text-light font-gobold">¡Hasta el próximo jueves!</h1>
      </div>
    </div>
  );
}

export default ComingSoon;
