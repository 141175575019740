import React, { useEffect, useRef, useState } from "react";
import Matter from "matter-js";
import axios from "axios";
import Swal from "sweetalert2";
import { URL_SERVER } from "../../api/ApiServer";
import "./Lottery.css";
import Nav from "../../Components/nav/Nav";

const CircleSimulation = () => {
  const scene = useRef(null);
  const circleCompositeRef = useRef(null); // Ref para el composite del círculo
  const angleRef = useRef(0); // Ref para almacenar el ángulo de rotación
  const angularVelocityRef = useRef(0.0); // Ref para la velocidad angular
  const [isSpinning, setIsSpinning] = useState(false); // Estado para controlar la animación

  useEffect(() => {
    const engine = Matter.Engine.create(); // Crear motor
    const world = engine.world; // Crear mundo

    // Crear renderer
    const render = Matter.Render.create({
      element: scene.current,
      engine: engine,
      options: {
        width: 800,
        height: 500,
        wireframes: false,
        background: "transparent", // Cambia el color de fondo aquí
      },
    });

    // Ancho y alto del canvas
    const width = 800;
    const height = 500;

    // Crear círculo grande contenedor
    const bigCircleRadius = 200;
    const centerX = width / 2;
    const centerY = height / 2;

    // Crear un composite para el círculo grande
    const circleComposite = Matter.Composite.create();
    const numParts = 90;
    const partWidth = 10;
    const partHeight = 12;

    for (let i = 0; i < numParts; i++) {
      const angle = (i * 4 * Math.PI) / 180;
      const x = centerX + Math.cos(angle) * bigCircleRadius;
      const y = centerY + Math.sin(angle) * bigCircleRadius;

      const part = Matter.Bodies.rectangle(x, y, partWidth, partHeight, {
        isStatic: true,
        friction: 0.5,
        angle: angle,
        render: {
          fillStyle: i % 2 === 0 ? "#fff" : "#F9BA1D", // Alternar colores
          strokeStyle: "#fff",
          lineWidth: 0,
        },
      });

      // Añadir partes pequeñas al composite
      Matter.Composite.add(circleComposite, part);
    }

    // Crear espátulas
    const spatulaCount = 6; // Número de espátulas
    const spatulaWidth = 5;
    const spatulaHeight = 50;
    for (let i = 0; i < spatulaCount; i++) {
      const angle = (i * 2 * Math.PI) / spatulaCount;
      const x =
        centerX + Math.cos(angle) * (bigCircleRadius - spatulaHeight / 2);
      const y =
        centerY + Math.sin(angle) * (bigCircleRadius - spatulaHeight / 2);

      const spatula = Matter.Bodies.rectangle(
        x,
        y,
        spatulaWidth,
        spatulaHeight,
        {
          isStatic: true,
          angle: angle + Math.PI / 2,
          render: {
            fillStyle: "transparent", // Color de las espátulas
            strokeStyle: "transparent",
            lineWidth: 0,
          },
        }
      );

      // Añadir espátulas al composite
      Matter.Composite.add(circleComposite, spatula);
    }

    circleCompositeRef.current = circleComposite;

    // Añadir el composite del círculo al mundo
    Matter.World.add(world, circleComposite);

    const getRandomColor = () => {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    // Crear círculos pequeños dentro del círculo grande
    const circles = Array.from({ length: 40 }, () => {
      // Generar posiciones aleatorias dentro del círculo grande
      const angle = Math.random() * Math.PI * 2;
      const radius = Math.random() * (bigCircleRadius - 20); // Restar 30 para evitar que los círculos se generen fuera del borde
      const x = centerX + Math.cos(angle) * radius;
      const y = centerY + Math.sin(angle) * radius;

      return Matter.Bodies.circle(x, y, 20, {
        restitution: 0.8, // Elasticidad del cuerpo
        friction: 0.1, // Fricción
        frictionAir: 0.01, // Fricción del aire
        render: {
          fillStyle: "#FABA1D", // Color de los círculos pequeños
        },
      });
    });

    // Añadir círculos pequeños al mundo
    Matter.World.add(world, circles);

    // Función de actualización para rotar el composite
    const update = () => {
      // Incrementar el ángulo de rotación según la velocidad angular
      angleRef.current += angularVelocityRef.current;

      if (circleCompositeRef.current) {
        Matter.Composite.rotate(
          circleCompositeRef.current,
          angularVelocityRef.current,
          {
            x: centerX,
            y: centerY,
          }
        );
      }

      Matter.Engine.update(engine, 1000 / 60); // Actualizar el motor
      requestAnimationFrame(update); // Llamar a la función de actualización en el siguiente fotograma
    };

    // Ejecutar motor y renderer
    Matter.Engine.run(engine);
    Matter.Render.run(render);
    update(); // Iniciar la actualización

    // Limpiar el renderizado al desmontar el componente
    return () => {
      Matter.Render.stop(render);
      Matter.World.clear(world);
      Matter.Engine.clear(engine);
      render.canvas.remove();
      render.textures = {};
    };
  }, []);

  // Función para iniciar el incremento y disminución de velocidad
  const handleSpin = async () => {
    if (isSpinning) return; // Evitar que se inicie el spin si ya está en curso

    setIsSpinning(true); // Bloquear el botón
    let currentVelocity = 0;
    const maxVelocity = 0.05; // Velocidad máxima
    const acceleration = 0.01; // Aceleración
    const deceleration = 0.005; // Desaceleración
    const accelerationDuration = 5000; // Duración de la aceleración en ms
    const decelerationDuration = 5000; // Duración de la desaceleración en ms

    // Incrementar velocidad
    const accelerate = () => {
      if (currentVelocity < maxVelocity) {
        currentVelocity += acceleration;
        angularVelocityRef.current = currentVelocity;
        setTimeout(accelerate, 100); // Ajustar la velocidad cada 100 ms
      } else {
        // Una vez que se alcanza la velocidad máxima, iniciar la desaceleración
        setTimeout(decelerate, accelerationDuration);
      }
    };

    // Disminuir velocidad
    const decelerate = async () => {
      if (currentVelocity > 0) {
        currentVelocity -= deceleration;
        if (currentVelocity < 0) currentVelocity = 0;
        angularVelocityRef.current = currentVelocity;
        setTimeout(decelerate, 100); // Ajustar la velocidad cada 100 ms
      } else {
        console.log("entramos en else");
        // Una vez que la velocidad es 0, realizar la solicitud
        try {
          const response = await axios.get(`${URL_SERVER}/getRandomCodeTest`);
          // Manejar los datos de la respuesta
          const { data } = response; // Aquí 'data' es el contenido de la respuesta
          Swal.fire({
            title: `Ganador(a): ${data.nombre} ${data.apellidos}`,
            icon: "info",
            showCancelButton: false, // Deshabilita el botón de cancelación
            allowOutsideClick: false, // Evita que se cierre al hacer clic fuera
            allowEscapeKey: false,
            confirmButtonText: "OK",
          });
        } catch (error) {
          // Manejar errores de la solicitud
          Swal.fire({
            title: "Error",
            text: `Hubo un problema con la solicitud: ${error.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        } finally {
          setIsSpinning(false); // Desbloquear el botón
        }
      }
    };

    // Iniciar el proceso
    accelerate();
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column min-vh-100 bg-image-main-1">
      <Nav />

      <div className="container-text1">
        <h1 className=" text-light font-gobold ">¡SORTEO FINAL!</h1>
      </div>

      <div ref={scene} className="mb-3" />
      <button
        type="button"
        className="Boton-sorteo"
        onClick={handleSpin}
        disabled={isSpinning}
      >
        Iniciar Sorteo
      </button>
    </div>
  );
};

export default CircleSimulation;
